import { Box, Button, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import Example from "../Components/Carousel";
import ImgMediaCard from "../Components/Card";
import Footer from "../Components/Footer";
import { Fade } from "react-reveal";
import ExperiencesCard from "../Components/ExperiencesCard";
import Hospitality from "../Components/Hospitality";
import LocalInformation from "../Components/LocalInformation";
import HomeSlider from "../Components/HomeSlider";
import SmallCarosal from "../Components/SmallCarosal";
import { Link } from "react-router-dom";
import FetchData from "../FetchData";
import Loading from "../Components/Loading";
import axios from "axios";
import GetBaseUrl from "../BaseUrl";
import { ToastContainer, toast } from "react-toastify";
import { BiLoaderAlt } from "react-icons/bi";
const nature1 = ["nature.png"];
const nature2 = ["Homeimg.png"];
const size = window.innerWidth;

const MainForHomePage = () => {
  const data1 = FetchData("experience");
  const dataHead = FetchData("pages/9");
  const dataHead12 = FetchData("pages/12");
  const data = FetchData("inspirations");
  const overview = FetchData("overview");
  const slider = FetchData("sliders");
  const [email,setEmail]=useState('');
  const [loading, setLoading] =useState(false);
  const base = GetBaseUrl();
  const HandelEmail=(value)=>{
    setEmail(value);
  }
  const HandelFormEmail=(e)=>{
    e.preventDefault();
    setLoading(true);
    (async () => {
      try {
        const response = await axios.post(base+'subscribe', {
          email:email
        });
        if(response.status===200){
          toast.success("Successfully Sent !", {
            position: toast.POSITION.TOP_LEFT,
          });
        }
        else{
          toast.error("email is already exist", {
            position: toast.POSITION.TOP_LEFT,
          });
        }
        setLoading(false)
      } catch (err) {
        if(err.message==="Network Error"){
          toast.success("Successfully Sent !", {
            position: toast.POSITION.TOP_LEFT,
          });
        }
        else if(err.message === "Request failed with status code 403"){
          console.log("email is already exist", err);  
          toast.error("email is already exist", {
            position: toast.POSITION.TOP_LEFT,
          }); 
        }
        else{
          toast.error(err.message, {
            position: toast.POSITION.TOP_LEFT,
          });
        }
          setLoading(false) 
       
      }
    })();
  }
  return (
    <>
      <ToastContainer />
      {data1 !== null && dataHead12 !== null && dataHead !== null && overview !== null && data !== null ? (
        <>
          <Example
            image={dataHead?.pages[0].photos[0]?.photo}
            title3="In harmony with nature"
            carosal="yes"
            arr={slider?.sliders}
          />
          {/* <Loading/> */}
          <div className="w-100 flex" style={{ backgroundColor: "#E8E5E2", minHeight: "200px" }}>
            <Container maxWidth="lg">
              <Grid
                container
                direction="row"
                alignItems="center"
         
                className="home-container"
                style={{ padding: "15px" }}
              >
                <Grid item xs={12} sm={8} md={4} className="my">
                  <Fade>
                    <div className="Nile1" style={{ color: "#2F3D4C", fontWeight: "normal" }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: overview?.Overviews[0].title.substring(0, 200),
                        }}
                        className="habd"
                      ></div>
                    </div>
                  </Fade>
                </Grid>
                <Grid item xs={12} sm={8} md={7.9}>
                  <Fade delay={300}>
                    <div className="Imagine p-2">
                      <div
                        dangerouslySetInnerHTML={{
                          __html:overview?.Overviews[0].description,
                        }}
                        className=""
                      ></div>
                    </div>
                  </Fade>
                </Grid>
              </Grid>
            </Container>
          </div>
          <Fade>
            {/* <img src="nature.png" alt="" style={{ width: "100%" }} /> */}
            <SmallCarosal myArr={overview?.Overviews[0]?.photos} />
          </Fade>
          <div className="w-100 flex " style={{ backgroundColor: "#E8E5E2", minHeight: "200px" }}>
            <Container>
              <Grid container direction="row" alignItems="center" className="home-container">
                <Grid  xs={12} sm={8} md={4}>
                  <Fade>
                    <div className="Nile1">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: overview?.Overviews[1].title.substring(0, 200),
                        }}
                        className="habd"
                      ></div>
                    </div>
                  </Fade>
                </Grid>
                <Grid item xs={12} sm={8} md={4}>
                  <Fade delay={300}>
                    <div className="Imagine">
                      <div className="cabins">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: overview?.Overviews[1].description,
                          }}
                          className="habd"
                        ></div>
                      </div>
                    </div>
                  </Fade>
                </Grid>
                <Grid item xs={12} sm={8} md={4}>
                  <Fade delay={600}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div className="Cabins my-2">
                        <span style={{ color: "#2F3D4C" }}>View Cabins and Suites</span>
                      </div>

                      <div style={{ textAlign: "right" }}>
                        <Button className=" px-5 " style={{ backgroundColor: "#2F3D4C" }}>
                          <Link to="/Accomodation" style={{ opacity: "1", color: "#fff" }}>
                            Check Availability
                          </Link>
                        </Button>
                      </div>
                    </div>
                  </Fade>
                </Grid>
              </Grid>
            </Container>
          </div>
          <Fade>
            <SmallCarosal myArr={overview?.Overviews[1]?.photos} />
          </Fade>
          <div className="w-100 flex" style={{ backgroundColor: "#E8E5E2", minHeight: "200px" }}>
            <Container maxWidth="lg">
              <Grid container direction="row" alignItems={"center"} style={{ padding: " 20px" }}>
                <Grid item xs={12} sm={8} md={4}>
                  <Fade>
                    <div className="Nile1">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: overview?.Overviews[2].title,
                        }}
                        className="habd"
                      ></div>
                    </div>
                  </Fade>
                </Grid>
                <Grid item xs={12} sm={8} md={4.3}>
                  <Fade delay={300}>
                    <div className="Imagine">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: overview?.Overviews[2].description,
                        }}
                        className="habd"
                      ></div>
                    </div>
                  </Fade>
                </Grid>
                <Grid item xs={12} sm={8} md={2.4} className="my-3">
                  <div style={{ textAlign: "right" }}>
                    <Button className=" px-5 " style={{ backgroundColor: "#2F3D4C" }}>
                      <Link to="/dining" style={{ opacity: "1", color: "#fff" }}>
                        Explore
                      </Link>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
          <div
            style={{
              width: "100%",
              minHeight: "30vh",
              backgroundColor: "#2F3D4C",
              padding: "15px 0",
            }}
          >
            <Container maxWidth="sm">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ padding: "5px 30px", color: "#E8E5E2" }}
              >
                <Fade>
                  <h2
                    style={{
                      textAlign: "center",
                      fontFamily: "SnellRoundhand",
                    }}
                  >
                    Experiences
                  </h2>
                </Fade>
                <Fade delay={300}>
                  {/* <p style={{ textAlign: "center", width: "" }}>
                    Venture through the mesmerizing cities of Luxor and Aswan, and indulge in <br />
                    the breathtaking views of the Nile accompanied by historic grandeur.
                    <br /> Relish in customized itineraries with ample time to unwind onboard.
                  </p> */}
                  <Fade>
              <div style={{ textAlign: "center"}}
                dangerouslySetInnerHTML={{
                  __html:dataHead12.pages[0].shortdescription ,
                  // dataHead12?.pages[0].description
                }}
              ></div>
            </Fade>
                </Fade>
              </Grid>
            </Container>
            <Container maxWidth="lg">
              {data1 !== null ? (
                <Fade>
                  <HomeSlider data1={data1} />
                </Fade>
              ) : (
                <Loading />
              )}
            </Container>
          </div>
          <div
            style={{
              width: "100%",
              minHeight: "40vh",
              backgroundColor: "#E8E5E2",
              padding: "30px 0",
            }}
          >
            <Hospitality myarr={overview.Overviews} />
            <Container maxWidth="md" className="my-4">
              <div className="flex">
                <div className="line" style={{ flex: "2" }}></div>
                <h2 className="local-info">Local Information</h2>
                <div className="line" style={{ flex: "2" }}></div>
              </div>
            </Container>
            <LocalInformation />
            <div
              style={{
                Width: "fit-content",
                margin: " 30px auto",
              }}
              className="flex subscribe "
            >
              <h4
                style={{
                  backgroundColor: "#2F3D4C",
                  color: "#E8E5E2",
                  padding: "22px",
                  margin: "0",
                  fontWeight: "500 ",
                  fontSize: "18px",
                }}
                className="newSettle my-1 our-Newsletter"
              >
                Subscribe to our Newsletter
              </h4>
              <div
                style={{
                  color: "#DADADA",
                  display: "flex",
                  backgroundColor: "#FFFFFF",
                  padding: "10px",
                  width: "fit-content",
                }}
                className="newSettle new-settle"
              >
                <form style={{ display: "flex" }} className="flex" onSubmit={(e)=>HandelFormEmail(e)}>
                  <input
                    type="email"
                    required
                    placeholder="Email"
                    style={{
                      width: "420px",
                      border: "1px solid #BDBDBD",
                      borderRadius: "10px",
                      marginRight: "10px",
                      padding: "10px",
                    }}
                    className="emailNew"
                    onChange={(e)=>HandelEmail(e.target.value)}
                  />
                  <div style={{}}>
                    <Button type="submit" className=" px-5" style={{ backgroundColor: "#2F3D4C", color: "#e8e5e2" }}>
                    {loading ? (
                      <BiLoaderAlt className="spinner" />
                    ) :(<p>submit</p>)}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={{ width: "100%", height: "100vh" }} className="flex">
          <Loading />
        </div>
      )}
    </>
  );
};

export default MainForHomePage;

{
  /* <Container maxWidth="lg" style={{ marginTop: "30px" }}>
  <Grid
    container
    spacing={3}
    direction="row"
    justifyContent="center"
    alignItems="center"
  >
    <Grid item xs={10} sm={8} md={4}>
      <ImgMediaCard />
    </Grid>
    <Grid item xs={10} sm={8} md={4}>
      <ImgMediaCard />
    </Grid>
    <Grid item xs={10} sm={8} md={4}>
      <ImgMediaCard />
    </Grid>
  </Grid>
</Container>
<Container maxWidth="lg" style={{ marginTop: "30px" }}>
  <Grid
    container
    spacing={3}
    direction="row"
    justifyContent="center"
    alignItems="center"
  >
    <Grid item xs={10} sm={8} md={4}>
      <ImgMediaCard />
    </Grid>
    <Grid item xs={10} sm={8} md={4}>
      <ImgMediaCard />
    </Grid>
    <Grid item xs={10} sm={8} md={4}>
      <ImgMediaCard />
    </Grid>
  </Grid>
</Container>{" "}
<Container maxWidth="md" style={{ marginTop: "30px" }}>
  <Grid
    container
    spacing={3}
    direction="row"
    justifyContent="center"
    alignItems="center"
  >
    <Grid item xs={10} sm={8} md={4}>
      <ImgMediaCard />
    </Grid>
    <Grid item xs={10} sm={8} md={4}>
      <ImgMediaCard />
    </Grid>
    <Grid item xs={10} sm={8} md={4}>
      <ImgMediaCard />
    </Grid>
  </Grid>
</Container>{" "}
<Container maxWidth="md" style={{ marginTop: "30px" }}>
  <Grid
    container
    spacing={3}
    direction="row"
    justifyContent="center"
    alignItems="center"
  >
    <Grid item xs={10} sm={8} md={4}>
      <ImgMediaCard />
    </Grid>
    <Grid item xs={10} sm={8} md={4}>
      <ImgMediaCard />
    </Grid>
    <Grid item xs={10} sm={8} md={4}>
      <ImgMediaCard />
    </Grid>
  </Grid>
</Container> */
}
