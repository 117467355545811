import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { Fade } from "react-reveal";
import SmallCarosal from "./SmallCarosal";
import { Link } from "react-router-dom";

const Hospitality = ({ myarr }) => {
  const dataHomeReg = ["/Mask.png", "/Mask.png", "/Mask.png"];
  console.log('myarr',myarr);
  
  return (
    <Container maxWidth="lg" >
       <Fade>
 <Grid
   container
   direction={'row-reverse'}
   justifyContent="center"
   alignItems="center"
   style={{
     padding: "5px 30px",
     color: "#2F3D4C",
     minHeight: "50vh",
     margin:'20px 0'
   }}
   spacing={2}
 >
   <Grid item xs={9} sm={4} md={2} >
    <Link target="_blank" to="https://slh.com/hotels/aqua-the-dahabeya/?roomsList=adults%3D2%2Bchildren%3D">
    <img src="/slh_black.png" alt="" width={"100%"} /></Link>
   
   </Grid>
   <Grid item xs={12} sm={8} md={9} className="flexCol" style={{ minHeight: "20vh", width: "100%" }}>
     <h2
       style={{
         textAlign: "center",
         fontFamily: "SnellRoundhand",
       }}
     >
       <div
         className="centerText coler"
       >A Member of Small Luxury Hotels of the World</div>
     </h2>
     <div className="Imagine">
     Aqua The Dahabeya is a proud member of Small Luxury Hotels of the World™, offering guests an exclusive Nile experience that blends SLH’s hallmark of boutique luxury with the dahabeya’s timeless elegance. This affiliation celebrates Aqua’s dedication to intimate travel along Egypt’s storied river, creating a journey that reflects both SLH standards and Aqua’s unique charm.
     </div>
   </Grid>
 </Grid>
</Fade>
      {myarr.slice(3).map((item,idx)=>(
 <Fade>
 <Grid
   container
   direction={idx%2===0?`row`:'row-reverse'}
   justifyContent="center"
   alignItems="center"
   style={{
     padding: "5px 30px",
     color: "#2F3D4C",
     minHeight: "50vh",
     margin:'20px 0'
   }}
   spacing={2}
 >
   <Grid item xs={12} sm={8} md={5.5}>
     <img src={item.photos[0]?.photo} alt="" width={"100%"} />
   </Grid>
   <Grid item xs={12} sm={8} md={5} className="flexCol" style={{ minHeight: "20vh", width: "100%" }}>
     <h2
       style={{
         textAlign: "center",
         fontFamily: "SnellRoundhand",
       }}
     >
       <div
         dangerouslySetInnerHTML={{
           __html: item.title,
         }}
         className="centerText coler"
       ></div>
     </h2>
     <div className="Imagine">
       <div
         dangerouslySetInnerHTML={{
           __html: item.description,
         }}
         className=""
       ></div>
     </div>
   </Grid>
 </Grid>
</Fade>
      ))}
     
    </Container>
  );
};

export default Hospitality;
